<template>
  <div class="card">
    <div class="card-body pt-0 mt-5 steps">
      <div class="row">
        <div class="col-md-5" v-if="active == 0">
          <div class="alert alert-primary d-flex align-items-center p-5 mb-10">
            <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
              <inline-svg src="/media/icons/duotune/general/gen048.svg" />
            </span>
            <div class="d-flex">
              <h4 class="mt-3 text-primary">Sample Product File Download</h4>
              <span class="ms-2">
              <a href="javascript:void()" class="mt-1 btn btn-sm btn-flex btn-light-primary btn-active-primary fw-bolder" @click="downloadSampleFile('xlsx')">
              <span class="svg-icon svg-icon-5 svg-icon-gray-500 me-1"> <i class="bi bi-file-earmark-arrow-down fs-4"></i> </span>
              {{generalConstants.DOWNLOAD_EXCEL}}
              </a>
              </span>

              <span class="ms-2">
              <a href="javascript:void()" class="mt-1 btn btn-sm btn-flex btn-light-primary btn-active-primary fw-bolder" @click="downloadSampleFile('csv')">
              <span class="svg-icon svg-icon-5 svg-icon-gray-500 me-1"> <i class="bi bi-file-earmark-arrow-down fs-4"></i> </span>
              {{generalConstants.DOWNLOAD_CSV}}
              </a>
              </span>
            </div>
          </div>
        </div>
      </div>
      <el-steps :active="active" finish-status="success">
        <el-step :title="productImportConstants.PRODUCTIMPORT" />
        <el-step :title="generalConstants.COLUMNMAPPING" />
        <el-step :title="productImportConstants.PRODUCTSTATISTICS" />
        <el-step :title="generalConstants.FINISH" class="text-end" />
      </el-steps>
  
      <el-form
        @submit.prevent="submitProductImportForm()"
        :model="productImportFormData"
        :rules="rules"
        ref="productFormRef"
        class="w-100 mt-10"
      >  
        <div class="row text-center" v-if="active == 0">
          <div class="col-md-12 fv-row">
            <label class="fw-bold">{{ generalConstants.SELECTTRADINGPARTNER }}</label>
            <el-form-item prop="tradingPartnerId" class="import-form-item">
              <el-select filterable clearable :placeholder="generalConstants.SELECTTRADINGPARTNER" v-model="productImportFormData.tradingPartnerId" size="large">
                <el-option :label="tradingData.store_name" :value="tradingData.trading_partner_id" :key="tradingData"  v-for="tradingData in tradingPartnerData"></el-option>
              </el-select>
            </el-form-item>
            <label class="fw-bold">{{ productImportConstants.PRODUCTEXCELFILE }}</label>
            <el-form-item prop="excelFiles" class="import-form-item">
              <el-upload
                ref="excelFiles"
                v-model="productImportFormData.excelFiles"
                :auto-upload="false"
                :limit="2"
                :multiple="false"
                :on-remove="handleRemove"
                v-on:change="handleChange"
                :file-list="fileList"
                drag
                class="upload-exel"
                action=""
              >
              <div class="fv-row">
                <div class="dropzone" id="kt_dropzonejs_example_1">
                    <div class="dz-message needsclick">
                        <i class="bi bi-file-earmark-arrow-up text-primary fs-3x"></i>
                        <div class="ms-4">
                            <h3 class="fs-5 fw-bolder text-gray-900 mb-1 mt-4" v-html="generalConstants.UPLOADFILEPLACETEXT"></h3>
                        </div>
                    </div>
                </div>
              </div>
            </el-upload>
            </el-form-item>
          </div>
        </div>

        <div class="row" v-if="active == 1" v-loading="loading">
          <div class="col-md-12 mb-5">
            <div class="row">
              <div class="col-md-2 col-3">
                <div class="mb-2">
                  <label><strong>{{ productImportConstants.SYSTEM_COLUMNS }}</strong></label>                
                </div>
              </div>
              <div class="col-md-10 col-9">
                <div class="mb-2">                  
                  <label><strong>{{ productImportConstants.EXCELCOLUMNS }}</strong></label>
                </div>
              </div>
            </div>
        </div>
        <div class="col-md-12 mb-5" v-for="(header, index) in productMappingData.keyData" :key="'key'+index">
          <div class="row" v-if="header.visible == true">
            <div class="col-md-2 col-3" :class="checkValidation(header.key) == true ? 'required' : ''">{{ header.name }}
            <span>
              <el-tooltip class="box-item" effect="dark" :content="header.help" placement="right" raw-content>
              <i class="bi bi-question-circle-fill"></i>
              </el-tooltip>
            </span>
            </div>
            <div class="col-md-10 col-9"> 
              <div class="d-flex align-items-center">
                  <div class="me-5">
                  <el-select filterable clearable v-model="header.mapping_key" :placeholder="productImportConstants.SELECT_MAPPING_COLUMN" size="large" @change="setTradingMappingData($event,header.key)">
                    <el-option
                      v-for="item in excelColumns"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                  </div>
                  <template v-if="header.key == 'height' || header.key == 'width'">
                    <div class="col-md-2 me-2">
                      <el-input size="medium" v-model="header.regular_expression" type="text" placeholder="Ex. 3-6" onkeypress='return event.charCode == 45 || (event.charCode >= 48 && event.charCode <= 57)' />
                    </div>
                    <label>Set regular expression to get height from mapping field.</label>
                  </template>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center" v-if="productImportFormData.tradingPartnerId != ''">
            <el-checkbox v-model="saveColumnCheckbox" @change="setSaveColumnData($event)" :label="productImportConstants.SAVE_COLUMN" />
        </div>
      </div>

        <span class="row mt-10" v-if="active == 2" v-loading="loading">  
          <div class="col-md-3 mb-1">
            <div class="col bg-light-primary px-6 py-8 rounded-2 me-7  mb-7">
              <span class="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
                <inline-svg src="/media/icons/duotune/abstract/abs027.svg" />
              </span>
              <span class="text-primary fw-bold fs-6"> {{ 'Total Rows' }} <h1 class="text-primary"><strong>{{ totalSkuCount }}</strong></h1></span>
            </div>
          </div>
          <div class="col-md-3 mb-1">
            <div class="col bg-light-danger px-6 py-8 rounded-2 me-7  mb-7">
              <span class="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
                <inline-svg src="/media/icons/duotune/abstract/abs027.svg" />
              </span>
              <span class="text-danger fw-bold fs-6"> {{ generalConstants.DUPLICATE_SKU }} <h1 class="text-danger"><strong>{{ totalDuplicateSkuFound }}</strong></h1></span>
            </div>
          </div>
          <div class="col-md-3 mb-1">
            <div class="col bg-light-success px-6 py-8 rounded-2 me-7  mb-7">
              <span class="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                <inline-svg src="/media/icons/duotune/abstract/abs027.svg" />
              </span>
              <span class="text-success fw-bold fs-6"> {{ productImportConstants.TOTALPRODUCTFOUND }} <h1 class="text-success"><strong>{{ totalProductCount }}</strong></h1></span>
            </div>
          </div>
          <div class="col-md-3 mb-1">
            <div class="col bg-light-success px-6 py-8 rounded-2 me-7  mb-7">
              <span class="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                <inline-svg src="/media/icons/duotune/abstract/abs027.svg" />
              </span>
              <span class="text-success fw-bold fs-6"> {{ productImportConstants.NEWPRODUCTS }} <h1 class="text-success"><strong>{{ totalNewProductCount }}</strong></h1></span>
            </div>
          </div>
          <div class="col-md-3">
            <div class="col bg-light-success px-6 py-8 rounded-2 me-7  mb-7">
              <span class="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                <inline-svg src="/media/icons/duotune/abstract/abs027.svg" />
              </span>
              <span class="text-success fw-bold fs-6"> {{ productImportConstants.EXISTINGPRODUCTS }} <h1 class="text-success"><strong>{{ totalExistProductCount }}</strong></h1></span>
            </div>
          </div>
          <div class="col-md-3">
            <div class="col bg-light-danger px-6 py-8 rounded-2 me-7  mb-7">
              <span class="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
                <inline-svg src="/media/icons/duotune/abstract/abs027.svg" />
              </span>
              <span class="text-danger fw-bold fs-6"> {{ generalConstants.SKIPPED_ROWS }} <h1 class="text-danger"><strong>{{ totalEmptySkuCount }}</strong></h1></span>
            </div>
          </div>
          <template v-if="totalExistProductCount > 0">
            <el-checkbox v-model="updateProductCheckbox" label="Check this checkbox if you want to update existing products record." />
          </template>
        </span>

        <span v-if="active == 4" v-loading="loading">  
          <el-row>
            <el-col :md="24">
            <el-result
              icon="success"
              title="Success"
              sub-title="Product Import Process Completed Successfully"
            >
              <template #extra>
                <el-button type="primary" @click.prevent="$router.push({ name: 'products'})">{{ productImportConstants.PRODUCTSBTN }}</el-button>
                <el-button type="primary" @click.prevent="$router.push({ name: 'import-products-history'})">{{ productImportConstants.PRODUCTHISTORYBTN }}</el-button>
              </template>
            </el-result>
          </el-col>
          </el-row>
        </span>
        <div class="col-md-12 mt-10 d-flex justify-content-between">
        <div>
          <button type="button" size="large" v-bind:class="btnHide" :disabled="disabled" v-if="active > 0" class=" btn btn-primary me-2" :loading="loading" @click="back">{{generalConstants.PREVIOUS}}</button>
        </div>
        <div>
          <button type="button" size="large" v-bind:class="btnHide" class="btn btn-primary me-2" v-if="active < 3" @click="submitProductImportForm(productFormRef)">{{generalConstants.NEXT}}<span v-if="loading" class="spinner-border spinner-border-sm align-middle ms-2"></span></button>
          <button type="button" size="large" class=" btn btn-secondary me-2" v-bind:class="btnHide" @click.prevent="$router.push({ name: 'products'})">{{generalConstants.CANCEL}}</button>
        </div>
      </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import { reactive, ref, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { formatText } from "@/core/helpers/common";
import globalConstant from "@/core/data/globalConstant.js";
import ApiService from "@/core/services/ApiService";
import { useRoute, useRouter } from "vue-router";
import { notificationFire } from "@/composable/notification.js";
import useEventBus from "../../composable/useEventBus";
import { setCurrentPageActionButtons } from "@/core/helpers/actionButtons";
import axios from "axios";

export default {
  name: "products-import",
  setup() {
    const { onEvent, emitEvent } = useEventBus()
    const route = useRoute();
    const router = useRouter();
    const generalConstants = globalConstant.general;
    const productImportConstants = globalConstant.productImport;
    const btnHide = ref();
    const formRef = ref(null);
    const loading = ref(false);
    const totalSkuCount = ref(0);
    const totalEmptySkuCount = ref(0);
    const totalProductCount = ref(0);
    const totalNewProductCount = ref(0);
    const totalExistProductCount = ref(0);
    const totalDuplicateSkuFound = ref(0);
    const file = ref();
    const active = ref(0);
    const disabled = ref(true);
    const excelData = ref([]);
    const tradingPartnerData = reactive([]);
    const finalizeProductData = ref([]);
    const excelColumns = ref([]);
    const saveColumnCheckbox = ref(false)
    const updateProductCheckbox = ref(false)
    const fileList = ref([]);
    const excelHeaderKeys = ref({
      keyData: []
    });

    //Get click event of toolbar buttons
    onEvent('actionName', (actionName) => {
      if(actionName == "back"){
        router.push('/products');
      }
    })

    const downloadSampleFile = (extension) => {
      window.open(process.env.VUE_APP_API_URL+'../../../sample_product.'+extension, '_blank');
    }

    const validateProductMappingField = ref({partner_sku_code: "", sku_code:""});

    const checkValidation = (val) => {
      if(val == 'partner_sku_code' || val == 'sku_code'){
        return true;
      }else{
        return false;
      }
    }

    const productImportFormData = ref({
      tradingPartnerId: "",
      excelFiles: "",
    });

    const rules = ref({
      excelFiles: [ { required: true, message: "Excel file is required", trigger: "change" } ],
    });

    const handleRemove = (e) => {
      productImportFormData.value.excelFiles = '';
    }

    const handleChange = (e) => {
      if(fileList.value.length > 1){
        fileList.value.splice(0, 1);
      }
      file.value = e.raw;
      productImportFormData.value.excelFiles = e.raw;
    }
    const setTradingMappingData = (val,mapping_key) => {
      tradingPartnerMappingData.value[mapping_key] = val;
      validateProductMappingField.value[mapping_key] = val;
    }

    const checkSelectedVal = (val,alldata, k) => {
      alldata.keyData.forEach((obj) => {
        if(obj.mapping_key == val){
          obj.mapping_key = '';
        }
      });
      alldata.keyData[k].mapping_key = val;
    }  

    const productFormRef = ref(null);

    const back = () => {
      if (active.value-- < 2) active.value = 0
    }

    const setSaveColumnData = (val) => {
      if(val.target.checked){
        let i = 0;
        productMappingData.keyData.forEach((obj) => {
          tradingPartnerMappingData.value[obj.key] = obj.mapping_key;
          i++;
        });
      }
    }

    const tradingPartnerMappingData = ref({
      partner_sku_code: "",
      partner_sku_description: "",
      sku_code: "",
      sku_description: "",
      product_name: "",
      price: "",
      description: "",
      size_label: "",
      height: "",
      width: "",
      attribute_name: "",
    });

    const productMappingData = reactive({
      keyData: [
        {
          key: "partner_sku_code",
          name: "Partner SKU",
          mapping_key: null,
          help: "This field represents platform system SKU",
          regular_expression: '',
          visible: true,
        },
        {
          key: "partner_sku_description",
          name: "Partner SKU Description",
          mapping_key: null,
          help: "This field represents platform system SKU description",
          regular_expression: '',
          visible: true,
        },
        {
          key: "sku_code",
          name: "System SKU",
          mapping_key: null,
          help: "This field represents system SKU",
          regular_expression: '',
          visible: true,
        },
        {
          key: "sku_description",
          name: "System SKU Description",
          mapping_key: null,
          help: "This field represents system sku description",
          regular_expression: '',
          visible: true,
        },
        {
          key: "product_name",
          name: "Product Name",
          mapping_key: null,
          help: "This field represents product name",
          regular_expression: '',
          visible: true,
        },
        {
          key: "price",
          name: "Product Price",
          mapping_key: null,
          help: "This field represents product price",
          regular_expression: '',
          visible: true,
        },
        {
          key: "description",
          name: "Product Description",
          mapping_key: null,
          help: "This field represents product description",
          regular_expression: '',
          visible: true,
        },
        {
          key: "size_label",
          name: "Size Label",
          mapping_key: null,
          help: "This field represents product size label",
          regular_expression: '',
          visible: true,
        },
        {
          key: "height",
          name: "Height",
          mapping_key: null,
          help: "This field represents product height",
          regular_expression: '',
          visible: true,
        },
        {
          key: "width",
          name: "Width",
          mapping_key: null,
          help: "This field represents product width",
          regular_expression: '',
          visible: true,
        },
        {
          key: "attribute_name",
          name: "Atribute Name",
          mapping_key: null,
          help: "This field represents attribute name",
          regular_expression: '',
          visible: true,
        },
      ],
    });

    let searchParams = {
      activeRecord: true,
    };
    const getTradingPartners = async () => {
      await ApiService.query("trading-partner", { params: searchParams })
        .then(({ data }) => {
          data.data.forEach((obj) => {
            tradingPartnerData.push({
              trading_partner_id: obj.trading_partner_id,
              store_name: obj.name,
            });
          });
        })
        .catch((error) => {
          let response = error.response;
          if (response) {
            let message = response.data.message;
            notificationFire(message, "error");
          }
        });
    }

  const submitProductImportForm = async (formEl) => {
    if (!productFormRef.value) {
      return;
    }
    productFormRef.value.validate((valid) => {
      if (!valid) return false;
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      if(active.value == 0){
        loading.value = true;
        disabled.value = true;
        let formData = new FormData();
        formData.append('file', file.value);
        formData.append('steps', active.value);
        formData.append('tradingPartnerId', productImportFormData.value.tradingPartnerId);
        axios.post('product-import-history/product-import', formData, config)
        .then(({ data }) => {
          if(productImportFormData.value.tradingPartnerId == ''){
            productMappingData.keyData[0].visible = false;
            productMappingData.keyData[1].visible = false;
          }else{
            productMappingData.keyData[0].visible = true;
            productMappingData.keyData[1].visible = true;
          }
          if(data.data.mappingData.product_mapping != null){
            let i = 0;
            for (const key in productMappingData.keyData) {
              let mainKey = productMappingData.keyData[i].key;
              productMappingData.keyData[i].mapping_key = (data.data.mappingData.product_mapping[productMappingData.keyData[i].key] != '' && data.data.excelHeaderKeys.includes(data.data.mappingData.product_mapping[productMappingData.keyData[i].key].toLowerCase())) ? data.data.mappingData.product_mapping[productMappingData.keyData[i].key].toLowerCase() : null;
              tradingPartnerMappingData.value[mainKey] = data.data.mappingData.product_mapping[productMappingData.keyData[i].key] != '' ? data.data.mappingData.product_mapping[productMappingData.keyData[i].key] : "";
              if(mainKey == 'partner_sku_code' || mainKey == 'sku_code'){
                validateProductMappingField.value[mainKey] = data.data.mappingData.product_mapping[mainKey] != null ? data.data.mappingData.product_mapping[key] : "";
              }
              i++;
            }
            saveColumnCheckbox.value = true;
          }
          if(data.data.excelHeaderKeys.length > 0){
            excelHeaderKeys.value.keyData = [];
            excelColumns.value = [];
            for (let i = 0; i < data.data.excelHeaderKeys.length; i++) {
              excelColumns.value.push({
                value: data.data.excelHeaderKeys[i],
                label: data.data.excelHeaderKeys[i],
              });
            }
          }
          sessionStorage.setItem("filename", data.data.filename);
          sessionStorage.setItem("fileext", data.data.fileext);
          notificationFire(data.message, "success");
          disabled.value = false;
          loading.value = false;
          if (active.value++ > 2) active.value = 0
        })
        .catch((error) => {
          let response = error.response;
          if (response) {
            let message = response.data.message;
            notificationFire(message, "error");
          }
          loading.value = false;
        });
      }

      if(active.value == 1){
          loading.value = true;
          disabled.value = true;
          let formData = new FormData();
          formData.append('file', file.value);
          formData.append('productMappingData', JSON.stringify(productMappingData.keyData));
          formData.append('validateProductMappingField', JSON.stringify(validateProductMappingField.value));
          formData.append('steps', active.value);
          formData.append('tradingPartnerId', productImportFormData.value.tradingPartnerId);
          if(sessionStorage.getItem("filename")){
            formData.append('filename', sessionStorage.getItem("filename"));
          }
          if(sessionStorage.getItem("fileext")){
            formData.append('fileext', sessionStorage.getItem("fileext"));
          }
          axios.post('product-import-history/product-import', formData, config)
          .then(({ data }) => {
            totalSkuCount.value = data.data.totalSkuFound;
            totalEmptySkuCount.value = data.data.totalEmptySkuFound;
            totalProductCount.value = (data.data.newProductCount + data.data.existProductCount);
            totalNewProductCount.value = data.data.newProductCount;
            totalExistProductCount.value = data.data.existProductCount;
            totalDuplicateSkuFound.value = data.data.totalDuplicateSkuFound;
            loading.value = false;
            disabled.value = false;
            if (active.value++ > 2) active.value = 0
          })  
          .catch((error) => {
            let response = error.response;
            if (response) {
              let message = response.data.message;
              notificationFire(message, "error");
            }
            loading.value = false;
            disabled.value = false;
          });
        }

        if(active.value == 2){
          loading.value = true;
          disabled.value = true;
          let formData = new FormData();
          formData.append('file', file.value);
          formData.append('productMappingData', JSON.stringify(productMappingData.keyData));
          formData.append('tradingPartnerId', productImportFormData.value.tradingPartnerId);
          formData.append('tradingPartnerMappingData', JSON.stringify(tradingPartnerMappingData.value));
          formData.append('saveColumnCheckbox', JSON.stringify(saveColumnCheckbox.value));
          formData.append('updateProductCheckbox', updateProductCheckbox.value == true ? '1' : '0');
          formData.append('steps', active.value);
          if(sessionStorage.getItem("filename")){
            formData.append('filename', sessionStorage.getItem("filename"));
          }
          if(sessionStorage.getItem("fileext")){
            formData.append('fileext', sessionStorage.getItem("fileext"));
          }
          ApiService.post('product-import-history/product-import', formData, config)
          .then(({ data }) => {
            notificationFire(data.message, "success");
            loading.value = false;
            disabled.value = false;
            active.value = 4;
            btnHide.value = 'd-none';
            sessionStorage.removeItem('filename');
            sessionStorage.removeItem('fileext');
          })  
          .catch((error) => {
            let response = error.response;
            if (response) {
              let message = response.data.message;
              notificationFire(message, "error");
            }
            loading.value = false;
            disabled.value = false;
          });
        }
    });
  };

    //Generate Current Page Toolbar Dynamic Buttons
    function generateHeaderToolbarButtons(){
      return setCurrentPageActionButtons([{"name": generalConstants.BACK , 'action': 'back','class': 'btn-primary','isLoader':false, 'iconClass':"bi bi-arrow-left fs-4" }]);
    }

    //Set breadcrumbs and get the products
    onMounted(() => {
      setCurrentPageBreadcrumbs(formatText(route.name), [{"name": formatText('products'), "path": "products"}, { name: formatText(route.name), active: true }]);
      getTradingPartners();
      generateHeaderToolbarButtons();
    })

    return {
      file,
      btnHide,
      loading,
      rules,
      tradingPartnerData,
      active,
      disabled,
      back,
      excelHeaderKeys,
      excelData,
      finalizeProductData,
      totalProductCount,
      totalNewProductCount,
      totalExistProductCount,
      totalSkuCount,
      totalEmptySkuCount,
      totalDuplicateSkuFound,
      submitProductImportForm,
      productFormRef,
      productImportFormData,
      checkSelectedVal,
      handleRemove,
      generalConstants,
      productImportConstants,
      downloadSampleFile,
      productMappingData,
      excelColumns,
      saveColumnCheckbox,
      setSaveColumnData,
      setTradingMappingData,
      handleChange,
      fileList,
      validateProductMappingField,
      checkValidation,
      tradingPartnerMappingData,
      updateProductCheckbox
    };
  },
};
</script>
<style>
  .upload-exel .el-upload-dragger{border:none !important;height:auto;padding:0 !important;}
  .import-form-item .el-form-item__content {
    justify-content: center;
  }
  .import-form-item .el-form-item__error {
    right: 0;
  }
  .steps .el-step__icon{
    width: 30px;
    height: 30px;
    font-size: 16px;
  }
  .steps .el-step__head.is-process{
    color: #009ef7;
    font-weight:700;
  }
  .steps .el-step.is-horizontal .el-step__line{
    top:50%;
    display: block;
  }
  .steps .el-step__head.is-process {  
  border-color: #009ef7;
}
.steps .el-step__main{
  color: #009ef7;
}
.steps .el-step__title.is-process{
  color: #009ef7 !important;
}
.steps  .el-step__title.is-success {
  color:#009ef7;
  font-weight: 700;
}
.steps .el-step__head.is-success {
  color:#fff;
}
.steps  .el-step__head.is-success .el-step__line{
  background-color: #009ef7;
}
.steps  .el-step__head.is-success .el-step__icon{
  border-color: #009ef7;
  background-color: #009ef7;
}
.steps  .el-step__head.is-success{
  border-color: #009ef7;
}
.steps .form-check-input {
  width: 1.30em;
  height: 1.30rem;
  margin-right: 10px;
}
</style>